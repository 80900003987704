//  Core

.grid-menu {

  .MuiGrid-item {
    border-right: $border-color solid 0;
    border-bottom: $border-color solid 1px;
    position: relative;
  }

  @include media-breakpoint-between(sm, xl) {

    .MuiGrid-item:nth-last-child(-n+2) {
      border-bottom-width: 0;
    }

    .MuiGrid-item:nth-child(1n) {
      border-right-width: 1px;
    }

    .MuiGrid-item:nth-child(2n) {
      border-right-width: 0;
    }
  }

  .MuiGrid-item:nth-last-child(-n+1) {
    border-bottom-width: 0;
  }

  &.grid-menu-3col {

    @include media-breakpoint-up(xl) {

      .MuiGrid-item:nth-last-child(-n+3) {
        border-bottom-width: 0;
      }

      .MuiGrid-item:nth-child(2n) {
        border-right-width: 1px;
      }

      .MuiGrid-item:nth-child(3n) {
        border-right-width: 0;
      }
    }
  }

  & > .btn {
    display: block;
    border: 0;
    min-width: 100%;
  }
}

// Grid dividers

.divider-v {
  position: absolute;
  top: 0;
  height: 100%;
  min-height: 26px;
  right: 0;
  width: 1px;
  background: $border-color;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    &.divider-v-lg {
      top: auto;
      bottom: 0;
      right: auto;
      left: 0;
      width: 100%;
      height: 1px;
      min-height: 1px;
    }
  }

  @include media-breakpoint-down(md) {
    &.divider-v-md {
      top: auto;
      bottom: 0;
      width: 100%;
      height: 1px;
      min-height: 1px;
    }
  }
}
