//  Core

.nav-link-icon {
  width: $nav-item-icon-size;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  font-size: $font-size-lg;
}
