//  Core

body {

  .theme-configurator {
    display: none;
  }

  @include media-breakpoint-up(md) {

    .theme-configurator {
      position: fixed;
      right: 0;
      top: 0;
      z-index: 1165;
      display: block;
      height: 100vh;

      &--heading {
        font-size: $theme-configurator-heading-font-size;
        padding: ($spacer / 1.5) ($spacer);
        background: $theme-configurator-heading-bg;
        border-color: $theme-configurator-heading-border-color;
        border-width: 1px 0;
        border-style: solid;
        font-weight: bold;
        text-transform: uppercase;
        color: $theme-configurator-heading-color;
        margin: 0;
      }

      &--list {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          padding: ($spacer) ($spacer * 1.5);
          display: flex;
          align-items: center;
          border-bottom: $border-color solid 1px;
          font-weight: bold;

          &:last-child {
            border-color: transparent;
          }
        }

        &__heading {
          padding-left: ($spacer / 2);

          span {
            display: block;
            opacity: .6;
            font-size: $font-size-sm !important;
          }
        }
      }

      &--drawer {
        background: $theme-configurator-drawer-bg;
        width: ($theme-configurator-drawer-width);
        height: 100%;
        box-shadow: 0 0 0 0 transparent;
        transition: $theme-configurator-transition;
        position: relative;
        z-index: 5;
      }

      &--swatches {
        text-align: center;
        padding: ($spacer / 2);

        .theme-config-swatch {
          width: 32px;
          height: 32px;
          margin: ($spacer / 2);
          @include border-radius($border-radius-sm);
          display: inline-block;
          opacity: .7;
          z-index: 10;
          position: relative;
          transition: $transition-base;
          cursor: pointer;
          border: $white solid 1px;
          transform-origin: center;

          &.active,
          &:hover {
            opacity: 1;
            transform: scale(1.3);
            z-index: 15;
            box-shadow: 0 0 0 2px $primary;
          }

          &--lg {
            width: 38px;
            height: 38px;
            border: $white solid 2px;
            box-shadow: 0 0 0 1px $gray-700;
          }
        }
      }

      .bg-current-scheme {
        width: 100%;
        height: 34px;
        @include border-radius($border-radius-sm);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .configurator-btn {
    @include border-right-radius(0);
  }

  .theme-config-wrapper {
    position: fixed;
    z-index: 1111;
    right: 0;
    top: 30%;
  }

  .theme-config-content {
    width: 440;
    height: 100%;
  }

  .drawer-wrapper-right {
    box-shadow: 2px 2px 22px #ccc;
  }

  .drawer-backdrop {
    background: transparent;
  }

  .drawer-close-btn {
    position: fixed;
    right: 16px;
    top: 16px;
  }
}
